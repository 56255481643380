import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party
import { QuillModule } from 'ngx-quill';
import { NzModalModule } from 'ng-zorro-antd/modal';

// Lib
import { CardModule, DropdownModule, NorbyIconModule, SpinModule } from 'uikit';
import { NorbyRichTextEditorComponent } from '../components/atoms/norby-rich-text-editor';

@NgModule({
  declarations: [NorbyRichTextEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    NzModalModule,
    SpinModule,
    CardModule,
    DropdownModule,
    NorbyIconModule
  ],
  exports: [NorbyRichTextEditorComponent]
})
export class NorbyRichTextEditorModule {}
