<form nz-form nzLayout="vertical" [formGroup]="formGroup">
  <div formGroupName="prompt">
    <nz-form-label>Prompt</nz-form-label>
    <div class="mb-3">
      <nz-form-control nzErrorTip="Please add a prompt for this block.">
        <textarea
          nz-input
          placeholder="What was..."
          formControlName="promptInstructions"
          [nzAutosize]="{ minRows: 6, maxRows: 16 }"
        ></textarea>
      </nz-form-control>
    </div>
  </div>

  <nz-divider></nz-divider>

  <norby-input
    label="Top padding"
    type="number"
    formControlName="paddingTop"
  ></norby-input>
  <norby-input
    label="Right padding"
    type="number"
    formControlName="paddingRight"
  ></norby-input>
  <norby-input
    label="Bottom padding"
    type="number"
    formControlName="paddingBottom"
  ></norby-input>
  <norby-input
    label="Left padding"
    type="number"
    formControlName="paddingLeft"
  ></norby-input>
</form>
