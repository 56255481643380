import { Injectable } from '@angular/core';
import { IProductAnalytics } from './product-analytics.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductAnalyticsServerService implements IProductAnalytics {
  constructor() {}

  dashboardPageViewStart({ path }: { path: string }) {}

  dashboardPageViewStop({ path }: { path: string }) {}
}
