import {
  instanceToPlain,
  plainToClass,
  Transform,
  Type
} from 'class-transformer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import { transformTimestampToDay } from '../tools';
import {
  ISubscriptionSchedule,
  Funnel,
  ProjectTiers,
  ProjectPeriod,
  ProjectPeriods
} from './billing';
import { ChargeType } from './general';
import {
  ISlugSmsSettings,
  BrandRegistrationStatus,
  ISlugMetadata,
  IStripeSlugMetadata,
  IBillingInfoAddress,
  ISlugReferrer,
  ISlugMetadataFeaturePermissions,
  IOnboardingInfo,
  TollFreeRegistrationStatusEnum
} from './ISlugMetadata';

export class TooltipMessage {
  message: string;
  tooltipType: 'link' | 'simple';
  url?: string;
}

export class AccountDates {
  @Transform(transformTimestampToDay, { toClassOnly: true })
  invoicePaymentLastFailedAt?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  billingStart?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  billingEnd?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  trialStart?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  trialEnd?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  usageStart?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  usageEnd?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  cancelAt?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  canceledAt?: dayjs.Dayjs;

  @Transform(transformTimestampToDay, { toClassOnly: true })
  retentionPromoLastRedeemedAt?: dayjs.Dayjs;
}

export class SmsSettings extends ISlugSmsSettings {
  private _brandMessages: string[];
  private _tollFreeMessages: string[];

  // TOLL FREE STATES
  get isTollFreeProvisioned(): boolean {
    return this.tollFreeProvisionedAt?.isBefore(dayjs());
  }

  get isTollFreeApprovalPending(): boolean {
    return (
      this.tollFreeRegistrationStatus === TollFreeRegistrationStatusEnum.PENDING
    );
  }

  get isTollFreeApprovalInReview(): boolean {
    return (
      this.tollFreeRegistrationStatus ===
      TollFreeRegistrationStatusEnum.IN_REVIEW
    );
  }

  get isTollFreeApprovalComplete(): boolean {
    return (
      this.tollFreeRegistrationStatus ===
      TollFreeRegistrationStatusEnum.COMPLETE
    );
  }

  get isTollFreeApprovalFailed(): boolean {
    return (
      this.tollFreeRegistrationStatus === TollFreeRegistrationStatusEnum.FAILED
    );
  }

  get tollFreeFailureMessages(): string[] {
    if (!this.tollFreeRegistrationFailureMessage) {
      this._tollFreeMessages = [];
    } else if (!this._tollFreeMessages) {
      this._tollFreeMessages =
        this.tollFreeRegistrationFailureMessage.split(';');
    }

    return this._tollFreeMessages;
  }

  get isTollFreeRecentlyCompleted(): boolean {
    if (!this.tollFreeRegistrationCompletedAt) {
      return false;
    }

    const diff = Math.abs(
      this.tollFreeRegistrationCompletedAt.diff(dayjs(), 'days')
    );

    return diff < 2;
  }

  // 10 DLC STATES
  get isBrandRegistrationRejected(): boolean {
    return this.brandRegistrationStatus === BrandRegistrationStatus.FAILED;
  }

  get isBrandRegistrationPending(): boolean {
    return this.brandRegistrationStatus === BrandRegistrationStatus.PENDING;
  }

  get isBrandRegistrationComplete(): boolean {
    return this.brandRegistrationStatus === BrandRegistrationStatus.COMPLETE;
  }

  get brandFailureMessages(): string[] {
    if (!this.brandRegistrationFailureMessage) {
      this._brandMessages = [];
    } else if (!this._brandMessages) {
      this._brandMessages = this.brandRegistrationFailureMessage.split(';');
    }

    return this._brandMessages;
  }
}

export type AccountLabels =
  | 'billingPeriodStartLabel'
  | 'billingPeriodEndLabel'
  | 'billingRangeLabel';

export type FeatureLimits = {
  chat: boolean;
  seats: number;
};

export class AccountStatus {
  private _labels: { [key in AccountLabels]: string } = {
    billingPeriodEndLabel: null,
    billingPeriodStartLabel: null,
    billingRangeLabel: null
  };

  static fromMetadata(metadata?: ISlugMetadata) {
    return plainToClass(AccountStatus, {
      slug: metadata?.slug,
      projectTier: metadata?.billingInfo?.projectTier,
      projectPeriod: metadata?.billingInfo?.projectPeriod,
      stripe: metadata?.stripe,
      dates: {
        invoicePaymentLastFailedAt:
          metadata?.billingInfo?.invoicePaymentLastFailedAt,
        billingStart: metadata?.billingInfo?.billingPeriodStartDate,
        billingEnd: metadata?.billingInfo?.billingPeriodEndDate,
        usageStart: metadata?.billingInfo?.usagePeriodStartDate,
        usageEnd: metadata?.billingInfo?.usagePeriodEndDate,
        trialStart: metadata?.billingInfo?.trialStartDate,
        trialEnd: metadata?.billingInfo?.trialEndDate,
        cancelAt: metadata?.billingInfo?.subscriptionCancelAtDate,
        canceledAt: metadata?.billingInfo?.subscriptionCanceledAtDate,
        retentionPromoLastRedeemedAt:
          metadata?.billingInfo?.retentionPromoLastRedeemedAt
      },
      sms: metadata?.sms,
      subscriptionSchedules: metadata?.billingInfo?.subscriptionSchedules,
      address: metadata?.billingInfo?.address,
      funnel: metadata?.billingInfo?.funnel ?? 'i',
      subscriptionDiscount: metadata?.billingInfo?.subscriptionDiscount,
      trialPeriod: metadata?.billingInfo?.trialPeriod,
      trialUsageWarnings: metadata?.billingInfo?.trialUsageWarnings,
      usageLimitLastApproachedAt:
        metadata?.billingInfo?.usageLimitLastApproachedAt,
      redeemedUnlimitedPlanFirstYearPromo:
        metadata?.billingInfo?.redeemedUnlimitedPlanFirstYearPromo,
      redeemedRetentionPromo: metadata?.billingInfo?.redeemedRetentionPromo,
      referrer: metadata?.referrer,
      featurePermissions: metadata?.featurePermissions,
      onboardingInfo: metadata?.onboardingInfo
    });
  }

  // TO DO: Improve
  isEqualTo(otherStatus: AccountStatus): boolean {
    return (
      JSON.stringify(instanceToPlain(this)) ===
      JSON.stringify(instanceToPlain(otherStatus))
    );
  }

  @Type(() => AccountDates)
  dates: AccountDates;

  @Type(() => ISubscriptionSchedule)
  subscriptionSchedules: ISubscriptionSchedule[];

  @Type(() => SmsSettings)
  sms: SmsSettings;

  slug: string;
  funnel: Funnel;
  projectTier: ProjectTiers;
  projectPeriod: ProjectPeriod;
  trialPeriod?: '2w' | 'month';
  trialUsageWarnings?: {
    [key in ChargeType]?: boolean;
  };
  usageLimitLastApproachedAt?: {
    [key in ChargeType]?: Date;
  };
  redeemedRetentionPromo?: boolean;
  redeemedUnlimitedPlanFirstYearPromo?: boolean;
  stripe: IStripeSlugMetadata;
  address: IBillingInfoAddress;
  referrer: ISlugReferrer;
  subscriptionDiscount?: {
    id: string;
    object: string;
    promotion_code: string;
    start: number;
    subscription: string;
    coupon?: {
      duration: string;
      name: string;
      percent_off: number;
      valid: boolean;
    };
  };
  featurePermissions: ISlugMetadataFeaturePermissions;
  onboardingInfo: IOnboardingInfo;

  get canUseChat(): boolean {
    return this.isBasicTier || this.isEnterpriseTier;
  }

  get hasScheduledChurn(): boolean {
    return (
      this.subscriptionSchedules?.length &&
      this.subscriptionSchedules[0].projectTier === ProjectTiers.FREE
    );
  }

  get isFreeTier(): boolean {
    return !this.projectTier || this.projectTier === ProjectTiers.FREE;
  }

  get isBasicTier(): boolean {
    return this.projectTier === ProjectTiers.BASIC;
  }

  get isEnterpriseTier(): boolean {
    return this.projectTier === ProjectTiers.ENTERPRISE;
  }

  get isInTrial(): boolean {
    const now = dayjs();
    return (
      this.dates.trialStart &&
      this.dates.trialEnd &&
      now.isBefore(this.dates.trialEnd) &&
      now.isAfter(this.dates.trialStart)
    );
  }

  get planName(): string {
    return this.isFreeTier
      ? 'Free'
      : this.isBasicTier
        ? 'Basic'
        : this.isEnterpriseTier
          ? 'Business'
          : '';
  }

  get onboardingPromoCode() {
    return this.onboardingInfo?.promoCode ?? null;
  }

  get trialDaysRemaining(): number {
    return this.isInTrial && this.dates.trialEnd.diff(dayjs(), 'days');
  }

  get isPaidPlan(): boolean {
    return this.isBasicTier || this.isEnterpriseTier;
  }

  get isAnnualPlan(): boolean {
    return this.projectPeriod === ProjectPeriods.ANNUAL;
  }

  get isMonthlyPlan(): boolean {
    return this.projectPeriod === ProjectPeriods.MONTHLY;
  }

  get hasBillingPeriod(): boolean {
    return !!(this.dates?.billingStart && this.dates?.billingEnd);
  }

  get hasPaymentMethod(): boolean {
    return !!this.stripe?.stripeDefaultPaymentMethodId;
  }

  get billingPeriodStartLabel(): string {
    this._labels.billingPeriodStartLabel =
      this._labels.billingPeriodStartLabel ||
      this.dates?.billingStart?.format('M/D/YYYY');
    return this._labels.billingPeriodStartLabel || '--';
  }

  get billingPeriodEndLabel(): string {
    this._labels.billingPeriodEndLabel =
      this._labels.billingPeriodEndLabel ||
      this.dates?.billingEnd?.format('M/D/YYYY');
    return this._labels.billingPeriodEndLabel || '--';
  }

  get billingPeriodLabel(): string {
    return `${this.billingPeriodStartLabel} - ${this.billingPeriodEndLabel}`;
  }

  get hasFailedPayment(): boolean {
    return !!this.dates?.invoicePaymentLastFailedAt;
  }

  get hasPrimaryPhoneNumber(): boolean {
    return !!this.sms?.primaryPhoneNumber;
  }

  get isPhoneNumberDisabled(): boolean {
    return this.hasPrimaryPhoneNumber && this.sms.phoneNumberDisabled;
  }

  get limits(): FeatureLimits {
    switch (this.projectTier) {
      case ProjectTiers.FREE:
        return {
          seats: 1,
          chat: false
        };
      case ProjectTiers.BASIC:
        return {
          seats: 10,
          chat: true
        };
      case ProjectTiers.ENTERPRISE:
        return {
          chat: true,
          seats: +(this.stripe?.stripePriceMetadata?.teamMemberLimit ?? 10)
        };
    }
  }
}

export const DEFAULT_ACCOUNT_STATUS = AccountStatus.fromMetadata();
