import { Injectable } from '@angular/core';

// 3rd party
import { Observable, from } from 'rxjs';

// Libs
import {
  IAccountIntegration,
  IAccountIntegrationMetadataType,
  IntegrationType
} from 'models';
import { QuerySummary } from '../observable-data';
import { IIntegrationsStoreService } from './integrations-store-interface';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsStoreServerService
  implements IIntegrationsStoreService
{
  suggestedDataIntegrations$: Observable<IntegrationType[]> = from([null]);

  getAccountIntegrations$(
    isLinked = true
  ): Observable<QuerySummary<IAccountIntegration[]>> {
    return from([null]);
  }

  getAccountIntegrationsForType$(
    integrationType: IAccountIntegrationMetadataType,
    isLinked = true
  ): Observable<QuerySummary<IAccountIntegration[]>> {
    return from([null]);
  }
}
