<div class="send-detail-view flex flex-col gap-4">
  <div class="border border-border-color-split p-4" *ngIf="!send">
    <root-skeleton [title]="false" [paragraph]="{ rows: 3, width: 150 }">
    </root-skeleton>
  </div>

  <div class="border border-border-color-split p-4" *ngIf="isEmail">
    <div *ngIf="send" class="flex flex-col gap-2 text-sm text-neutral-800">
      <div>
        From: <strong>{{ fromName }}</strong>
      </div>
      <div *ngIf="send.sentAt">
        Sent:
        <strong>{{ send.sentAt | date: "EEEE, MMMM d, y 'at' h:mm a" }}</strong>
      </div>
    </div>
  </div>

  <div class="border border-border-color-split" *ngIf="send">
    <div class="ql-readonly-body text-base max-w-full overflow-hidden">
      <div *ngIf="isEmail && !send.hasBeenSent">
        <div
          *ngFor="let block of send.blocks; let i = index; trackBy: trackBy"
          class="flex justify-center break-all w-full"
          [innerHtml]="
            block.getHtml(slug, send?.theme, send?.contentBlockMap) | safeHtml
          "
        ></div>
      </div>
      <div
        *ngIf="isEmail && send.hasBeenSent"
        [innerHtml]="send?.renderedBody | safeHtml"
      ></div>
    </div>

    <div *ngIf="isSms">
      <div *ngIf="send.attachments?.length > 0" class="flex flex-row flex-wrap">
        <div
          *ngFor="
            let attachment of send.attachments;
            let i = index;
            trackBy: attachmentTrackBy
          "
          class="mr-3 mb-3 w-24 h-24 rounded-lg bg-neutral-200 flex justify-center items-center overflow-hidden"
        >
          <lib-soft-image-view
            [imageUrl]="attachment.source"
            [alt]="attachment.filename"
          >
          </lib-soft-image-view>
        </div>
      </div>

      <div
        *ngIf="send.blocks?.length > 0 && send.firstTextBlockContent"
        class="rounded-tl-lg rounded-tr-lg rounded-br-lg bg-neutral-200 text-lg text-neutral-900 p-6 mr-6 whitespace-pre-wrap"
      >
        {{ send.firstTextBlockContent }}
      </div>
    </div>
  </div>
</div>
