import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

// Libs
import { ConversationMessage } from 'models';
import { DeviceService } from '../../services';
import { BaseComponent } from 'uikit';

type MenuItem = {
  label: string;
  icon: string;
  handler: () => void;
};

@Component({
  standalone: false,
  selector: 'app-conversation-message-actions-view',
  templateUrl: './conversation-message-actions-view.component.html',
  styleUrls: ['./conversation-message-actions-view.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ConversationMessageActionsView extends BaseComponent {
  @Input() message: ConversationMessage;
  @Input() useDropdown = true;

  @Output() onThumbsUpClick = new EventEmitter<void>();
  @Output() onThumbsDownClick = new EventEmitter<void>();
  @Output() onExplainAnswerClick = new EventEmitter<void>();
  @Output() onShareClick = new EventEmitter<void>();
  @Output() menuStateChanged = new EventEmitter<boolean>();

  menuItems: MenuItem[] = [];
  isMobile = false;

  constructor(private _device: DeviceService) {
    super();
  }

  get timestampRelative(): string {
    const date = dayjs(this.message.createdAt);
    return date.fromNow();
  }

  get timestampAbsolute(): string {
    const date = dayjs(this.message.createdAt);
    return date.format('lll');
  }

  ngOnInit() {
    this._device.isMobile$
      .pipe(this.takeUntilDestroy)
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngOnChanges() {
    super.ngOnChanges();
    this._updateMenuItems();
  }

  private _updateMenuItems() {
    this.menuItems = [
      ...(this.message?.hasAttachments
        ? [
            {
              label: 'Explain this',
              icon: 'feather/help-circle',
              handler: () => this.onExplainAnswerClick.emit()
            }
          ]
        : []),
      {
        label: 'Good response',
        icon: 'feather/thumbs-up',
        handler: () => this.onThumbsUpClick.emit()
      },
      {
        label: 'Bad response',
        icon: 'feather/thumbs-down',
        handler: () => this.onThumbsDownClick.emit()
      },
      {
        label: 'Share',
        icon: 'feather/share',
        handler: () => this.onShareClick.emit()
      }
    ];
  }

  handleVisibleChanged(event) {
    this.menuStateChanged.emit(event);
  }
}
