import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// 3rd party
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';

// Libs
import {
  IQueryResult,
  ApiSurfaces,
  ENDPOINTS,
  PaginatedQuerySummary,
  NlqRecurringReport,
  NlqRecurringReportResults
} from 'models';
import { ErrorService } from 'uikit';

// App
import { IReportsStoreService } from './reports-store.service.interface';
import { ApiService } from '../api';
import { DeviceService } from '../device';
import {
  ObservableDataService,
  RealtimeSocketEventHandler
} from '../observable-data';
import {
  RealtimeServerSocketMessage,
  REPORT_CREATED_IN_SLUG_TOPIC,
  REPORT_UPDATED_IN_SLUG_TOPIC,
  REPORT_DELETED_IN_SLUG_TOPIC
} from '../socket';
import { ReportsFilterArgs } from './types';

@Injectable({
  providedIn: 'root'
})
export class ReportsStoreService implements IReportsStoreService {
  constructor(
    private _obs: ObservableDataService,
    private _api: ApiService,
    private _error: ErrorService,
    private _device: DeviceService
  ) {}

  getReports$(
    args: ReportsFilterArgs
  ): Observable<PaginatedQuerySummary<NlqRecurringReport>> {
    // Function used to fetch pages
    const lookup = this.getReports;

    // Function used to transform lookup results and
    // incorporate them into the stream
    const transformer = (
      res: IQueryResult,
      currentValue: NlqRecurringReport[]
    ) => {
      const hasNextPage = res?.pageInfo?.hasNextPage;
      const sends =
        res?.edges?.map((edge) => {
          return plainToClass(NlqRecurringReport, edge?.node);
        }) ?? [];

      return {
        items: [...(currentValue ?? []), ...sends],
        cursor: hasNextPage ? res?.pageInfo?.maxCursor : null
      };
    };

    const handlers: RealtimeSocketEventHandler[] = [
      {
        event: REPORT_CREATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: NlqRecurringReport[]
        ) => {
          const addOn = plainToClass(NlqRecurringReport, event?.data);
          currentValue?.unshift(addOn);
          return [...currentValue];
        }
      },
      {
        event: REPORT_UPDATED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: NlqRecurringReport[]
        ) => {
          const addOn = plainToClass(NlqRecurringReport, event?.data);
          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );
          if (currentIdx > -1) {
            currentValue[currentIdx] = addOn;
          } else {
            currentValue?.unshift(addOn);
          }
          return [...currentValue];
        }
      },
      {
        event: REPORT_DELETED_IN_SLUG_TOPIC,
        payload: {
          resourceId: this._device.currentSlug
        },
        transformer: (
          event: RealtimeServerSocketMessage,
          currentValue: NlqRecurringReport[]
        ) => {
          const addOn = plainToClass(NlqRecurringReport, event?.data);

          const currentIdx = currentValue?.findIndex(
            (node) => node?.id === addOn?.id
          );

          if (currentIdx > -1) {
            currentValue.splice(currentIdx, 1);
            return [...currentValue];
          }

          return currentValue;
        }
      }
    ];

    return this._obs.query$<NlqRecurringReport>({
      args,
      lookup,
      transformer,
      handlers
    });
  }

  getReports = async (
    filters: ReportsFilterArgs
  ): Promise<NlqRecurringReportResults> => {
    try {
      const ret = await this._api.get<NlqRecurringReportResults>(
        ApiSurfaces.API,
        ENDPOINTS.reports,
        filters
      );
      return ret;
    } catch (e) {
      const is404 = e instanceof HttpErrorResponse && e.status === 404;
      if (!is404) {
        this._error.displayError(e);
      }
    }
  };
}
