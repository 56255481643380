<div class="invite-user-container">
  <p class="text-neutral-600">
    Invite a teammate to join {{ slug?.accountInfo?.title }}.
  </p>

  <p class="text-neutral-600" *ngIf="!showRoles">
    Teammates will be able to manage connected integrations, receive reports,
    and start conversations with Norby.
  </p>

  <form [formGroup]="formGroup" nz-form nzLayout="vertical">
    <nz-form-item>
      <nz-form-label nzFor="banner">Email</nz-form-label>
      <input
        type="email"
        nz-input
        placeholder="Email"
        formControlName="email"
        nzSize="large"
        required
      />
    </nz-form-item>

    <nz-form-item *ngIf="showRoles">
      <nz-form-label nzFor="banner">Role</nz-form-label>
      <nz-form-control [nzExtra]="rolesTpl">
        <nz-select nzSize="large" formControlName="role">
          <nz-option
            [nzLabel]="option"
            *ngFor="let option of ROLE_OPTIONS"
            [nzValue]="option"
          >
          </nz-option>
        </nz-select>
      </nz-form-control>
      <ng-template #rolesTpl
        >See a full breakdown of roles and permissions in our
        <a
          href="https://help.withnorby.com/add-manage-team-permissions"
          target="_blank"
          >Knowledge Base</a
        >.</ng-template
      >
    </nz-form-item>

    <div class="bg-neutral-200 p-6 rounded-lg" *ngIf="userIsGlobalAdmin">
      <h3>Super Admin Tools</h3>

      <nz-form-item>
        <nz-form-label nzFor="onboarding"
          >[DEPRECATED] Onboarding</nz-form-label
        >
        <p class="text-neutral-600">
          Send an onboarding invite. This functionality is deprecated and
          probably shouldn't be used.
        </p>
        <nz-select formControlName="onboarding">
          <nz-option
            [nzLabel]="'No onboarding flow'"
            [nzValue]="false"
          ></nz-option>
          <nz-option
            [nzLabel]="'Show onboarding flow'"
            [nzValue]="true"
          ></nz-option>
        </nz-select>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label nzFor="expiresInMinutes">Temporary Access</nz-form-label>
        <p class="text-neutral-600">
          Grant temporary access to someone for testing or support purposes.
          Temporary team members will not appear in the list of team members.
        </p>
        <nz-select formControlName="expiresInMinutes">
          <nz-option
            [nzLabel]="duration.label"
            nzCustomContent
            *ngFor="let duration of DURATIONS"
            [nzValue]="duration.value"
          >
            {{ duration.label }}
          </nz-option>
        </nz-select>
      </nz-form-item>
    </div>
  </form>
</div>
