// 3rd party
import {
  Exclude,
  instanceToInstance,
  instanceToPlain,
  plainToClass,
  Type
} from 'class-transformer';
import { BehaviorSubject } from 'rxjs';

// Lib
import {
  ButtonSingleSendBlock,
  DividerSingleSendBlock,
  FooterSingleSendBlock,
  HeaderSingleSendBlock,
  ImageSingleSendBlock,
  NlqPromptSingleSendBlock,
  NlqSummarySingleSendBlock,
  QuoteSingleSendBlock,
  SingleSendBlock,
  SpacerSingleSendBlock,
  TextSingleSendBlock,
  NlqKeyTakeawaysSingleSendBlock
} from './ISingleSend';
import { Theme } from '../ISlug';
import { TimeZone } from '../timezone';
import { hashString } from '../../tools';
import { SearchPageInfo, ElasticSearchSortOrder } from '../ISearchResults';

export enum RecurringReportRecurrenceTypes {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  NONE = 'none'
}

export type RecurringReportRecurrenceType = `${RecurringReportRecurrenceTypes}`;

export enum NlqRecurringReportDeliveryTypeEnum {
  EMAIL = 'email',
  SLACK = 'slack'
}

export type NlqRecurringReportDeliveryType =
  `${NlqRecurringReportDeliveryTypeEnum}`;

export class IPSQLNlqRecurringReport {
  id!: string;
  slug!: string;
  subject!: string | null | undefined;
  deliveryType!: NlqRecurringReportDeliveryType;
  blocks!: SingleSendBlock[];
  theme!: Theme | undefined;
  recurrenceType!: RecurringReportRecurrenceType; // TEXT NOT NULL
  recurrenceDayOfWeek!: number | null | undefined; // INTEGER (0-6) for weekly/biweekly/monthly schedules (nullable)
  recurrenceDayOfMonth!: number | null | undefined; // INTEGER (1-31) for monthly schedules (nullable)
  recurrenceTime!: string; // TIME NOT NULL, typically in HH:MM:SS format
  recurrenceTimeZone!: TimeZone;
  nextRunAt!: Date | null | undefined;
  lastRunAt!: Date | null | undefined;
  createdAt!: Date; // TIMESTAMP, typically in ISO string format
  modifiedAt!: Date; // TIMESTAMP, typically in ISO string format
  recipients!: string[];
  reportName!: string;
  createdBy?: string;
  lastModifiedBy?: string;
  hidden?: boolean;
  disabled?: boolean;
}

export class NlqRecurringReport extends IPSQLNlqRecurringReport {
  @Exclude()
  protected _changes$ = new BehaviorSubject<NlqRecurringReport>(null);

  @Exclude()
  readonly changes$ = this._changes$.asObservable();

  @Exclude()
  private _hash: number;

  @Exclude()
  private _blockHash: number;

  @Type(() => Theme)
  theme: Theme;

  @Type(() => SingleSendBlock, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'blockType',
      subTypes: [
        { value: TextSingleSendBlock, name: 'text' },
        { value: HeaderSingleSendBlock, name: 'header' },
        { value: ImageSingleSendBlock, name: 'image' },
        { value: SpacerSingleSendBlock, name: 'spacer' },
        { value: FooterSingleSendBlock, name: 'footer' },
        { value: DividerSingleSendBlock, name: 'divider' },
        { value: ButtonSingleSendBlock, name: 'button' },
        { value: QuoteSingleSendBlock, name: 'quote' },
        { value: NlqSummarySingleSendBlock, name: 'nlqSummary' },
        { value: NlqPromptSingleSendBlock, name: 'nlqPrompt' },
        { value: NlqKeyTakeawaysSingleSendBlock, name: 'nlqKeyTakeaways' }
      ]
    }
  })
  readonly blocks: SingleSendBlock[];

  readonly id: string;

  get hash() {
    if (!this._hash) {
      const theme = this.theme?.hash ?? 0;
      const props = `
          ${this.slug}
          ${this.recurrenceType}
          ${this.recurrenceDayOfWeek}
          ${this.recurrenceDayOfMonth}
          ${this.recurrenceTime}
          ${this.recurrenceTimeZone}
          ${this.recipients?.join()}
          ${this.reportName}
          ${this.blockHash},
          ${this.deliveryType},
          ${this.hidden},
          ${this.disabled},
          ${this.subject},
          ${theme}
        `;
      this._hash = hashString(props);
    }

    return this._hash;
  }

  get blockHash() {
    if (!this._blockHash) {
      const blocks = this.blocks?.reduce((a, block) => `${a}${block.hash}`, '');
      this._blockHash = hashString(blocks);
    }

    return this._blockHash;
  }

  get firstTextBlockContent() {
    const firstTextBlock = this.blocks?.find(
      (block) => block.blockType === 'text'
    ) as TextSingleSendBlock;
    return firstTextBlock?.body;
  }

  get isValid() {
    return !!this.reportName && !!this.slug;
  }

  updateProperties(
    properties: Partial<IPSQLNlqRecurringReport>,
    emitEvent = true
  ) {
    // Only update props that aren't @Excluded in the toObject transform
    const props =
      properties instanceof NlqRecurringReport
        ? properties.toObject()
        : properties;

    for (const key in props) {
      this[key] = properties[key];
    }

    // Clear hashes
    this._hash = null;

    if (properties?.blocks) {
      this._blockHash = null;
    }

    // Publish changes
    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  static new() {
    return plainToClass(NlqRecurringReport, {
      deliveryType: 'email',
      recurrenceType: 'none',
      recurrenceDayOfWeek: 1,
      recurrenceDayOfMonth: 1,
      recurrenceTime: '10:00:00',
      recurrenceTimeZone: null,
      blocks: [
        HeaderSingleSendBlock.new().toObject(),
        TextSingleSendBlock.new().toObject()
      ]
    });
  }

  clone(withSend?: Partial<NlqRecurringReport>) {
    const clone = instanceToInstance(this);
    if (withSend) {
      clone.updateProperties(withSend);
    }
    return clone;
  }

  toObject() {
    return instanceToPlain(this) as IPSQLNlqRecurringReport;
  }

  static fromObject(object: any) {
    return plainToClass(NlqRecurringReport, {
      ...object
    });
  }

  removeBlockAtIndex(idx: number, emitEvent = true) {
    this.blocks?.splice(idx, 1);
    this._blockHash = null;
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  updateBlockAtIndex(idx: number, block: SingleSendBlock, emitEvent = true) {
    this.blocks[idx] = block;
    this._blockHash = null;
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  insertBlockAtIndex(idx: number, block: SingleSendBlock, emitEvent = true) {
    if (idx < 0) {
      return;
    } else if (idx > this.blocks.length) {
      this.blocks.push(block);
    } else {
      this.blocks.splice(idx, 0, block);
    }

    this._blockHash = null;
    this._hash = null;

    if (emitEvent) {
      this._changes$.next(this);
    }
  }

  toTestDTO(): TestNlqRecurringReportDto {
    const { subject, deliveryType, theme, recipients, slug } = this.toObject();

    const blocks =
      this.blocks?.reduce(
        (acc, block) => (block.isEmpty ? acc : [...acc, block.toObject()]),
        []
      ) ?? [];

    return {
      subject,
      deliveryType,
      theme,
      recipients,
      blocks,
      slug
    };
  }

  toSendDTO(): SendNlqRecurringReportDto {
    const { subject, deliveryType, theme, recipients, slug, id } =
      this.toObject();

    const blocks =
      this.blocks?.reduce(
        (acc, block) => (block.isEmpty ? acc : [...acc, block.toObject()]),
        []
      ) ?? [];

    return {
      id,
      subject,
      deliveryType,
      theme,
      recipients,
      blocks,
      slug
    };
  }

  toCreateDTO(): CreateNlqRecurringReportDto {
    const {
      subject,
      deliveryType,
      theme,
      recurrenceType,
      recurrenceDayOfWeek,
      recurrenceDayOfMonth,
      recurrenceTime,
      recurrenceTimeZone,
      recipients,
      reportName,
      slug,
      hidden,
      disabled
    } = this.toObject();

    const blocks =
      this.blocks?.reduce(
        (acc, block) => (block.isEmpty ? acc : [...acc, block.toObject()]),
        []
      ) ?? [];

    const isWeekly = recurrenceType === 'weekly';
    const isBiweekly = recurrenceType === 'biweekly';
    const isMonthly = recurrenceType === 'monthly';

    return {
      subject,
      deliveryType,
      theme,
      recurrenceType,
      recurrenceTime,
      recurrenceTimeZone,
      recipients,
      reportName,
      blocks,
      slug,
      hidden: hidden ?? false,
      disabled: disabled ?? false,

      ...((isWeekly || isBiweekly) && {
        recurrenceDayOfWeek
      }),

      ...(isMonthly && {
        recurrenceDayOfMonth
      })
    };
  }
}

export class CreateNlqRecurringReportDto {
  subject?: string | null | undefined;
  deliveryType!: NlqRecurringReportDeliveryType;
  blocks!: SingleSendBlock[];
  theme?: Theme | undefined;
  recurrenceType!: RecurringReportRecurrenceType; // TEXT NOT NULL
  recurrenceDayOfWeek?: number | null | undefined; // INTEGER (0-6) for weekly/biweekly/monthly schedules (nullable)
  recurrenceDayOfMonth?: number | null | undefined; // INTEGER (1-31) for monthly schedules (nullable)
  recurrenceTime!: string; // TIME NOT NULL, typically in HH:MM:SS format
  recurrenceTimeZone?: TimeZone;
  recipients!: string[];
  reportName!: string;
  slug: string;
  hidden?: boolean;
  disabled?: boolean;
}

export class TestNlqRecurringReportDto {
  subject?: string | null | undefined;
  deliveryType!: NlqRecurringReportDeliveryType;
  blocks!: SingleSendBlock[];
  theme?: Theme | undefined;
  recipients!: string[];
  slug: string;
}

export class SendNlqRecurringReportDto {
  subject?: string | null | undefined;
  deliveryType!: NlqRecurringReportDeliveryType;
  blocks!: SingleSendBlock[];
  theme?: Theme | undefined;
  recipients!: string[];
  slug: string;
  id: string;
}

export class GetNlqRecurringReportDto {
  limit: string;
  query: string;
  offset?: string;
  slug?: string;
  createdAtSort?: ElasticSearchSortOrder;
  modifiedAtSort?: ElasticSearchSortOrder;
  nextRunAtSort?: ElasticSearchSortOrder;
  lastRunAtSort?: ElasticSearchSortOrder;
}

export class NlqRecurringReportResultEdge {
  cursor?: string;
  offset?: number;
  node!: NlqRecurringReport;
}

export class NlqRecurringReportResults {
  pageInfo!: SearchPageInfo;
  edges!: NlqRecurringReportResultEdge[];
}
