<div class="send-detail-view-container-wrapper">
  <div class="send-detail-view-container">
    <div class="space-wrapper">
      <div
        class="unthemed-card-styles block overflow-hidden border-solid border border-border-color-split rounded-none"
      >
        <div class="header">
          <div class="meta-wrapper">
            <div *ngIf="report" class="meta">
              <p>
                From: <strong>{{ fromName }}</strong>
              </p>
            </div>

            <root-skeleton
              *ngIf="!report"
              [title]="false"
              [paragraph]="{ rows: 3, width: 150 }"
            >
            </root-skeleton>
          </div>
        </div>
      </div>

      <div
        class="unthemed-card-styles block overflow-hidden border-solid border border-border-color-split rounded-none"
      >
        <div class="send-body ql-readonly-body text-base">
          <div
            *ngFor="
              let block of report?.blocks;
              let i = index;
              trackBy: trackBy
            "
            class="flex justify-center break-all w-full"
            [innerHtml]="block.getHtml(slug, report?.theme) | safeHtml"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
