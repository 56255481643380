import { Injectable } from '@angular/core';

import { IIntegrationsService } from './integrations.service.interface';
import {
  IAccountIntegration,
  IAccountIntegrationJob,
  IAccountIntegrationMetadataType,
  IUpdateIntegrationFeaturesDTO,
  IntegrationType
} from 'models';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsServerService implements IIntegrationsService {
  getAccountIntegrations = async (
    isLinked: boolean
  ): Promise<IAccountIntegration[]> => {
    return null;
  };

  getAccountIntegrationsForType = async ({
    integrationType,
    isLinked = true
  }: {
    integrationType: IAccountIntegrationMetadataType;
    isLinked: boolean;
  }): Promise<IAccountIntegration[]> => {
    return null;
  };

  async linkShopifyToSlug(slug: string): Promise<boolean> {
    return false;
  }

  async linkAttentive({
    username,
    hostname,
    password,
    port
  }: {
    username: string;
    hostname: string;
    password: string;
    port: string;
  }): Promise<boolean> {
    return false;
  }

  async chooseGoogleAnalyticsProperty(
    accountId: string,
    property: string
  ): Promise<boolean> {
    return false;
  }

  async chooseEventbriteOrganization(
    accountId: string,
    organizationId: string
  ): Promise<boolean> {
    return false;
  }

  async chooseMetaAdAccount(
    accountId: string,
    adAccountId: string
  ): Promise<boolean> {
    return false;
  }

  async chooseTikTokAdsAccount(
    accountId: string,
    adAccountId: string
  ): Promise<boolean> {
    return false;
  }

  async chooseGoogleAdAccount(
    accountId: string,
    adAccountId: string
  ): Promise<boolean> {
    return false;
  }

  async setPermittedSlackUsers(
    accountId: string,
    selectedEmails: string[]
  ): Promise<boolean> {
    return false;
  }

  async linkBeehiiv(apiKey: string): Promise<boolean> {
    return false;
  }

  async linkSkio(apiKey: string): Promise<boolean> {
    return false;
  }

  async linkImpact({
    accountSid,
    authToken
  }: {
    accountSid: string;
    authToken: string;
  }): Promise<boolean> {
    return false;
  }

  async unlinkFacebook(id: string, deleteData: boolean): Promise<void> {
    return null;
  }

  async unlinkInstagram(id: string): Promise<void> {
    return null;
  }

  async unlinkAccountIntegration(
    integration: IntegrationType,
    id: string
  ): Promise<void> {}

  async patchPermissions(args: {
    accountId: string;
    features: IUpdateIntegrationFeaturesDTO;
  }): Promise<void> {
    return null;
  }

  async getIntegrationToken(): Promise<string> {
    return null;
  }

  async getAccountIntegrationJob(id: string): Promise<IAccountIntegrationJob> {
    return null;
  }
}
