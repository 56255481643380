import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';

import { plainToClass } from 'class-transformer';
import { filter, map } from 'rxjs';

import { DEFAULT_BLOCK_PADDING, NlqKeyTakeawaysSingleSendBlock } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'edit-nlq-key-takeaways-block',
  templateUrl: './edit-nlq-key-takeaways-block.component.html',
  styleUrls: ['./edit-nlq-key-takeaways-block.component.less']
})
export class EditNlqKeyTakeawaysBlockComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() block: NlqKeyTakeawaysSingleSendBlock;

  @Output() onUpdatedBlock: EventEmitter<NlqKeyTakeawaysSingleSendBlock> =
    new EventEmitter<NlqKeyTakeawaysSingleSendBlock>();
  formGroup: UntypedFormGroup;

  constructor(private _formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    // Fallback values are necessary in case an old text block body is edited before padding is adjusted
    this.formGroup?.patchValue(
      {
        paddingTop: this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        paddingRight: this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        paddingBottom: this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        paddingLeft: this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING
      },
      { emitEvent: false }
    );
  }

  private _initForm() {
    this.formGroup = this._formBuilder.group({
      promptInstructions: this._formBuilder.group({
        promptInstructions: [
          this.block?.promptInstructions,
          Validators.required
        ]
      }),
      paddingTop: [
        this.block?.paddingTop ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingRight: [
        this.block?.paddingRight ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingBottom: [
        this.block?.paddingBottom ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ],
      paddingLeft: [
        this.block?.paddingLeft ?? DEFAULT_BLOCK_PADDING,
        [Validators.required, Validators.min(0), Validators.max(300)]
      ]
    });

    this.formGroup.valueChanges
      .pipe(
        filter(() => this.formGroup.valid),
        map((value) =>
          plainToClass(NlqKeyTakeawaysSingleSendBlock, {
            ...this.block,
            ...value
          })
        ),
        this.takeUntilDestroy
      )
      .subscribe((block) => this.onUpdatedBlock.emit(block));
  }
}
