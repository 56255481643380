import { ISubscriptionPlan, ProjectTiers } from '../types/billing';
import { DowngradeStages, DowngradeStagesEnum } from '../types/downgrade';

export const CURRENT_PLANS_MAP: { [key: string]: ISubscriptionPlan } = {
  [ProjectTiers.FREE]: {
    type: ProjectTiers.FREE,
    monthlyPrice: 0,
    yearlyPrice: 0,
    title: 'Free',
    summary: 'For solo and side projects.',
    promo: null,
    features: [
      'Monthly email reports',
      '5 data sources',
      '1 team member',
      'No customizations'
    ]
  },
  [ProjectTiers.BASIC]: {
    type: ProjectTiers.BASIC,
    monthlyPrice: 99,
    yearlyPrice: 89,
    title: 'Basic',
    summary: 'For growing brands and teams.',
    promo: 'Covers Shopify Basic, Advanced, and Plus',
    features: [
      'Weekly, monthly, and quarterly reporting',
      '5 data sources',
      '3 report customizations',
      'Unlimited email recipients',
      'Early access to Chat (Beta)',
      'Slack & Teams integrations (Beta)'
    ]
  },
  [ProjectTiers.ENTERPRISE]: {
    type: ProjectTiers.ENTERPRISE,
    monthlyPrice: 499,
    yearlyPrice: 399,
    title: 'Business',
    summary: 'For large brands and agencies.',
    promo: null,
    features: [
      'Customized email reporting',
      'Manage multiple brands',
      'Unlimited data sources',
      'Unlimited team members',
      'Early access to Deep Research (Beta)',
      'Early access to Dashboards (Beta)',
      'Early access to Spreadsheets (Beta)'
    ]
  }
};

export const CURRENT_PLANS: ISubscriptionPlan[] =
  Object.values(CURRENT_PLANS_MAP);

export const DOWNGRADE_FEATURES_CONFIRMATION = {
  [ProjectTiers.FREE]: [
    'I will be limited to monthly reports',
    'I will be limited to 1 team member',
    'I will lose access to customized reporting',
    'I will lose access to Chat'
  ],
  [ProjectTiers.BASIC]: [],
  [ProjectTiers.ENTERPRISE]: []
};

export type DowngradeStageCopyBlock = {
  title: string;
  subtitle: string;
};

export const DOWNGRADE_STAGES_COPY: {
  [key in DowngradeStages]: DowngradeStageCopyBlock;
} = {
  [DowngradeStagesEnum.REASON]: {
    title: 'Before you go...',
    subtitle: 'Why are you downgrading?'
  },
  [DowngradeStagesEnum.PAIN_POINTS]: {
    title: 'Help us improve',
    subtitle: 'Tell us about your experience'
  },
  [DowngradeStagesEnum.LACKING_FEATURES]: {
    title: 'Help us improve',
    subtitle: 'Tell us about your experience'
  },
  [DowngradeStagesEnum.CHANGE_IN_NEEDS]: {
    title: 'Help us improve',
    subtitle: 'Tell us about your experience'
  },
  [DowngradeStagesEnum.RETENTION_PROMO]: null,
  [DowngradeStagesEnum.CONFIRMATION]: {
    title: 'Confirm',
    subtitle:
      'Changes will take effect at the end of your billing cycle on {1}.'
  }
};
