export const ENDPOINTS = {
  support: {
    ticket: 'support/ticket'
  },
  ai: {
    conversation: '/ai/conversation',
    shortener: '/message/shortener',
    exampleQuestions: '/ai/example_questions',
    categoryQuestions: '/ai/example_questions/category'
  },
  onboarding: {
    slugRequest: 'v2/onboarding/slug_request',
    slugValidation: 'onboarding/slug_valid',
    unauthorizedSlugRequest: 'onboarding/unauthorized_slug_request',
    completeSlugRequest: 'onboarding/complete_slug_provision',
    socialSlugRequest: 'onboarding/social_login_slug_request'
  },
  reports: '/ai/recurring_report',
  singlesend: '/singlesend',
  singleSendV2: '/v2/singlesend',
  singleSendSummaryV2: '/v2/singlesend/summary',
  organization: {
    member: {
      invite: '/organization/member/invite',
      update: '/organization/member/update',
      remove: '/organization/member/remove',
      inviteAck: '/organization/member/invite_ack'
    },
    notificationSettings: '/organization/notification_settings',
    transferOwnership: '/organization/transfer_ownership',
    mailing: {
      replyTo: '/organization/mailing/reply_to'
    },
    sms: {
      alphaSender: '/organization/sms/alpha_sender'
    },
    account_settings: '/organization/account_settings',
    generate_api_key: '/organization/generate_api_key',
    brandRegistration: '/organization/sms/brand_registration',
    tollFreeRegistration: '/organization/sms/toll_free_registration',
    brandOtpVerification: '/organization/sms/brand_otp_verification',
    googleUserConsent: '/organization/google_user_consent'
  },
  content: {
    sampleCopy: 'content/sample_copy'
  },
  event: '/event',
  link: '/link',
  landingPage: '/landing_page',
  signup: '/drop',
  newsletter: '/newsletter',
  analytics: {
    trackEntityInteraction: '/analytics/track_entity_interaction',
    beacon: '/beacon/interaction',
    trackProductAnalytics: '/analytics/track_product_analytics'
  },
  auth: {
    refresh: '/auth/refresh',
    google: '/auth/google',
    analytics: '/auth/analytics_token',
    search: '/v2/auth/search_key',
    stream: '/auth/stream_key',
    email: {
      promote: '/auth/email/promote'
    },
    login: {
      phone: {
        request: '/auth/phone/login/request',
        verify: '/auth/phone/login/verify'
      },
      email: {
        request: '/auth/email/login/request',
        verify: '/auth/email/login/verify'
      }
    },
    onboarding: {
      google: '/auth/google/onboarding'
    },
    logout: '/auth/logout',
    integration: 'auth/integration_token',
    billing: '/auth/billing_token',
    calendar: '/auth/calendar_token'
  },
  contacts: {
    csvImport: 'contact/import',
    csvImportV2: '/v2/contact/import',
    edit: '/contact'
  },
  search: {
    contacts: '/search/contacts',
    content: '/search/content',
    contactList: '/search/contact_list',
    singleSends: '/search/single_sends',
    exampleQuestions: '/search/admin/nlq_example_question',
    internalQueries: '/search/admin/nlq_internal_query'
  },
  integration: {
    accountIntegrations: '/integration',
    accountIntegrationsByType: '/integration/type',
    linkFacebook: 'integration/auth/facebook',
    google: 'integration/auth/google',
    accountIntegrationJob: 'integration/job',
    linkShopify: 'integration/auth/shopify/app_store/link_account'
  },
  featureFlags: '/feature_flag',
  segment: {
    preview: '/segment/preview'
  }
};
