import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// 3rd party
import { QuillModules } from 'ngx-quill';

// Libs
import { BaseComponent, MockDeviceService, MockFileUploadService } from 'uikit';
import { uuidv4 } from 'models';

@Component({
  standalone: false,
  selector: 'norby-rich-text-editor',
  templateUrl: './norby-rich-text-editor.component.html',
  styleUrls: [
    '../../../../../../../node_modules/quill/dist/quill.core.css',
    '../../../../../../../node_modules/quill/dist/quill.snow.css',
    './norby-rich-text-editor.component.less'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NorbyRichTextEditorComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NorbyRichTextEditorComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() placeholder: string;
  @Input() isBorderless = false;
  @Input() isDisabled = false;
  @Input() hasImageSelection: boolean = true;
  @Input() initWithFocus: boolean = false;

  @ViewChild('fileRef') fileRef: ElementRef;
  @ViewChild('imageToolbarIcon') imageToolbarIcon: ElementRef;

  val: string;
  quillEditor;
  quillModules: QuillModules;
  hasFocus = false;
  isUploading = false;
  uuid: string;
  isMobile: boolean = false;

  private _onTouched = (_?: any) => {};
  private _onChanged = (_?: any) => {};
  private _touched = false;
  private _lastEmittedValue: string;
  private _toolbarListener: { (): void };

  get shouldRenderEditor(): boolean {
    return isPlatformBrowser(this._platform) && !!this.quillModules;
  }

  constructor(
    @Inject(PLATFORM_ID) private _platform,
    private _upload: MockFileUploadService,
    private _cdr: ChangeDetectorRef,
    private _r2: Renderer2,
    private _device: MockDeviceService
  ) {
    super();

    // Quill is not safe for SSR
    if (isPlatformBrowser(this._platform)) {
      Promise.all([import('quill-image-resizor'), import('quill')]).then(
        ([ImageResizor, Quill]) => {
          Quill?.default?.register(
            'modules/imageResizor',
            ImageResizor?.default
          );

          this.quillModules = {
            imageResizor: {},
            keyboard: {
              bindings: {
                tab: false,
                handleEnter: {
                  key: 13,
                  handler: () => {}
                }
              }
            }
          };
          this._cdr.detectChanges();
        }
      );
    }
  }

  ngOnInit(): void {
    this.uuid = uuidv4();
    this._device.isMobile$
      .pipe(this.takeUntilDestroy)
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this._toolbarListener) {
      this._toolbarListener();
    }
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  registerOnChange(fn: any) {
    this._onChanged = (val) => {
      this._lastEmittedValue = val;
      fn(val);
    };
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  writeValue(value: string) {
    if (value === this._lastEmittedValue) {
      return;
    }

    if (value !== this.val) {
      this._lastEmittedValue = value;
      this.val = value;

      this._cdr.detectChanges();
    }
  }

  handleOpenSystemChooseFileDialog() {
    this.fileRef.nativeElement.click();
  }

  handleEditorCreated(editorInstance) {
    if (this.imageToolbarIcon) {
      this._toolbarListener = this._r2.listen(
        this.imageToolbarIcon.nativeElement,
        'mousedown',
        (e) => {
          e.preventDefault();
        }
      );
    }

    this.quillEditor = editorInstance;

    if (this.isDisabled) {
      this.quillEditor.disable(true);
    } else if (this.initWithFocus) {
      this.hasFocus = true;
      this.quillEditor.setSelection(this.quillEditor.getLength(), 0);
    }
  }

  async handleFileSelected(event: any) {
    const file = event?.target?.files?.[0];
    if (!file) {
      return;
    }

    this.isUploading = true;
    this._cdr.detectChanges();
    const imageUrl = await this._upload.uploadFile(file);
    this.isUploading = false;
    this._cdr.detectChanges();

    if (imageUrl?.length && this.quillEditor) {
      const img = `<img style="max-width:100%" src="${imageUrl}"></img>`;
      const range = this.quillEditor.getSelection();
      this.quillEditor.clipboard.dangerouslyPasteHTML(
        range?.index || 0,
        img,
        'user'
      );
    }
  }

  handleOnContentChanged(editorContent) {
    const updatedHtmlVal = (editorContent?.html ?? '').replace(/&nbsp;/gi, ' ');

    if (updatedHtmlVal !== this._lastEmittedValue && this.hasFocus) {
      this._lastEmittedValue = updatedHtmlVal;
      this._onChanged(updatedHtmlVal);
      this._markAsTouched();
    }
  }
}
