import { Injectable } from '@angular/core';

// 3rd party
import { from, Observable } from 'rxjs';

// Libs
import {
  NlqRecurringReport,
  NlqRecurringReportResults,
  PaginatedQuerySummary
} from 'models';

// App
import { IReportsStoreService } from './reports-store.service.interface';
import { ReportsFilterArgs } from './types';

@Injectable({
  providedIn: 'root'
})
export class ReportsStoreServerService implements IReportsStoreService {
  getReports$ = (
    args: ReportsFilterArgs
  ): Observable<PaginatedQuerySummary<NlqRecurringReport>> => from([null]);

  getReports = async (
    filters: ReportsFilterArgs
  ): Promise<NlqRecurringReportResults> => ({
    pageInfo: null,
    edges: new Array(3).fill({ node: null })
  });
}
