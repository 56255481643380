import { IThemeDTO, StageBlocks } from '../types';

export const DEFAULT_ONBOARDING_COPY_ID = 'default';
export const DEFAULT_ONBOARDING_LANDING_COPY_ID = 'defaultLandingPage';

export const ONBOARDING_URL = 'https://join.nor.by/';
export const HOW_TO_NORBY_URL =
  'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/How%20to%20Norby%20in%2060%20Seconds.m4v?alt=media&token=1bcedf60-bafe-4aac-80b7-55d5c4a26844';
export const DEFAULT_AVATAR_IMG_URL =
  'https://firebasestorage.googleapis.com/v0/b/magic-sauce.appspot.com/o/defaultOnboardingAvatar.jpeg?alt=media&token=27e5fd1a-4bd1-47c3-ad28-2a83e74ad751';
export const NORBY_DASHBOARD_IMG_URL =
  'https://firebasestorage.googleapis.com/v0/b/magic-sauce-assets/o/Norby_dashboard.png?alt=media&token=9e32f860-3d2e-4c44-98ca-dac24bd0d510';

export const DEFAULT_THEME: IThemeDTO = {
  id: '1',
  name: null,
  layout: {
    backgroundColor: '#fff'
  },
  fonts: {
    font: {
      importUrl:
        'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap',
      fontFamily: "'Noto Sans', sans-serif",
      displayName: 'Noto Sans'
    }
  },
  text: {
    color: '#101840'
  }
};

export const DEFAULT_LANDING_PAGE = {
  id: 'newLandingPage',
  slug: 'newSlug',
  label: 'Home',
  title: 'Home',
  default: true,
  description: '',
  entityIds: [],
  published: false,
  createdAt: new Date(),
  modifiedAt: new Date(),
  blocks: [
    {
      blockType: 'header',
      style: 'creator',
      backgroundColor: 'transparent',
      height: 160,
      avatarImage: { url: DEFAULT_AVATAR_IMG_URL }
    },
    {
      blockType: 'profile',
      showTitle: true,
      showBio: true
    }
  ]
};

export const DEFAULT_CHAT_DESIGN_PARTNER_LANDING_PAGE_COPY = `
<div class="text-lg text-neutral-700">
  <h3>What is Norby AI?</h3>
  <p>
    Norby AI is an advanced AI agent that centralizes your marketing and sales data
    and allows you to use natural language to get real-time answers, reports, and
    insights. Norby AI can send you custom-tuned email reports on your ideal cadence,
    chat with you on the web, or even answer quick questions directly from Slack.
  </p>
  <p></p>
  <h3>How does the Design Partnership Program work?</h3>
  <p>As part of the Program, Partner will:</p>
  <ul>
    <li>
      Give feedback via video calls, emails, or screen recordings depending on
      bandwidth.
    </li>
    <li>
      Participate in up to 6 feedback sessions per Term depending on bandwidth.
    </li>
    <li>Connect sales and marketing integrations in the Product.</li>
    <li>
      Ask questions to train your brand's custom AI based on the connected
      platforms.
    </li>
    <li>Appear as a customer in private customer lists.</li>
  </ul>
  <p></p>
  <p>As part of the Program, Provider will:</p>
  <ul>
    <li>
      Give a discount to Partner if Partner signs an annual agreement to use the
      Product after completing the Program. (Discount amount: 30.0%)
    </li>
    <li>
      Develop additional integrations or features per Partner's requirements as
      needed and mutually agreed upon in writing.
    </li>
  </ul>
  <p></p>
  <p>At the end of the Term, Partner will have the option to:</p>
  <ul>
    <li>Sign an annual or monthly agreement to continue using the Product.</li>
    <li>
      Appear as a customer on the Norby AI website and public customer lists.
    </li>
    <li>Provide a case study that can be shared publicly.</li>
  </ul>
  <p></p>
  <h3>Are there any other Terms?</h3>
  <p>
    This Agreement has 2 parts: (1) this Cover Page and (2) the
    <a
      href="https://www.withnorby.com/legal/design-partner-agreement"
      rel="noopener noreferrer"
      target="_blank"
      >Norby AI Design Partner Standard Terms</a
    >. If there is any inconsistency between the parts of the Agreement, the
    Cover Page will control over the Standard Terms. Capitalized words have the
    meanings given below.
  </p>
  <table>
    <tbody>
      <tr>
        <td><strong>Effective Date</strong></td>
        <td><em>Date Partner accepts invite to Program</em></td>
      </tr>
      <tr>
        <td><strong>Term</strong></td>
        <td><em>3 months</em></td>
      </tr>
      <tr>
        <td><strong>Governing Law</strong></td>
        <td><em>The laws of the State of Delaware</em></td>
      </tr>
      <tr>
        <td><strong>Fees</strong></td>
        <td><em>There are no Fees under this Agreement</em></td>
      </tr>
    </tbody>
  </table>
  <p></p>
</div>
`;

export const DEFAULT_COPY_BLOCKS: StageBlocks = {
  name: {
    title: "Hi, I'm Norby",
    body: 'What should I call you?'
  },
  title: {
    title: 'Nice to meet you, {1}',
    body: "What's the name of your brand or agency?"
  },
  teamSize: {
    title: 'How big is your team?',
    body: ''
  },
  businessTitle: {
    title: "What's your title?",
    body: ''
  },
  integrations: {
    title: "Let's talk tools",
    body: 'Which tools and platforms do you use?'
  },
  questions: {
    title: 'Almost done, {1}',
    body: 'What KPIs, metrics, or behaviors are you interested in tracking or understanding better?'
  },
  email: {
    title: 'Log in',
    body: "I'm setting up your workspace. Choose how you'd like to continue."
  },
  welcome: {
    body: 'Start by connecting a data source. Norby will schedule your reports automatically once your data sources are connected.',
    title: 'Welcome'
  },
  promo: {
    body: 'You have a special promo code on your account. Add a payment method to use it.',
    title: "You've got credit!",
    hidden: true
  }
};
