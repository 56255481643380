import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EditEmailTextBlockViewComponent } from '../components/templates/email-block-edit-views';
import { NorbyRichTextEditorModule } from './norby-rich-text-editor.module';

@NgModule({
  declarations: [EditEmailTextBlockViewComponent],
  imports: [CommonModule, NorbyRichTextEditorModule, ReactiveFormsModule],
  exports: [EditEmailTextBlockViewComponent]
})
export class EmailBlockEditViewsModule {}
