<root-spin [spinning]="isUploading || !shouldRenderEditor">
  <div
    class="rich-text-wrapper text-base border rounded-sm bg-white text-neutral-800 border-neutral-300 hover:border-neutral-500"
    [id]="uuid"
    [class.focused]="hasFocus"
    [class.borderless]="isBorderless"
    [class.disabled]="isDisabled"
  >
    <ng-container *ngIf="shouldRenderEditor">
      <quill-editor
        bounds=".rich-text-wrapper"
        [placeholder]="placeholder"
        [modules]="quillModules"
        [ngModel]="val"
        (onEditorCreated)="handleEditorCreated($event)"
        (onContentChanged)="handleOnContentChanged($event)"
      >
        <div quill-editor-toolbar>
          <span class="ql-formats">
            <button class="ql-bold" title="Bold"></button>
            <button class="ql-italic" title="Italic"></button>
            <button class="ql-underline" title="Underline"></button>
            <button class="ql-strike" title="Strike"></button>
          </span>

          <span class="ql-formats">
            <button
              class="ql-list"
              value="ordered"
              type="button"
              title="Ordered"
            ></button>
            <button
              class="ql-list"
              value="bullet"
              type="button"
              title="Bullet"
            ></button>
          </span>

          <span class="ql-formats">
            <select class="ql-header" title="Headings">
              <option value="1">Heading 1</option>
              <option value="2">Heading 2</option>
              <option value="3">Heading 3</option>
              <option value="4">Heading 4</option>
              <option value="5">Heading 5</option>
              <option value="6">Heading 6</option>
              <option value="" selected>Normal</option>
            </select>
          </span>

          <span class="ql-formats">
            <select class="ql-align" title="Text Alignment">
              <option value="" selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </span>

          <span class="ql-formats">
            <button class="ql-link" title="Insert Link"></button>
          </span>

          <span
            *ngIf="hasImageSelection"
            #imageToolbarIcon
            title="Insert Image"
            class="ql-formats"
          >
            <norby-icon
              class="cursor-pointer"
              name="image"
              (click)="handleOpenSystemChooseFileDialog()"
            ></norby-icon>
          </span>

          <span class="ql-formats">
            <button class="ql-clean" title="Clear Formatting"></button>
          </span>
        </div>
      </quill-editor>
    </ng-container>
    <root-card *ngIf="!shouldRenderEditor" class="placeholder"></root-card>
  </div>
</root-spin>

<div class="invisible-input-container">
  <input
    #fileRef
    type="file"
    accept="image/*"
    (change)="handleFileSelected($event)"
  />
</div>
