import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MockAccountStoreService } from '../../../services';
import { TooltipMessage } from 'models';
import { BaseComponent } from 'uikit';

@Component({
  standalone: false,
  selector: 'norby-custom-message-tooltip',
  templateUrl: './norby-custom-message-tooltip.component.html',
  styleUrls: ['./norby-custom-message-tooltip.component.less']
})
export class NorbyCustomMessageTooltipComponent
  extends BaseComponent
  implements OnInit
{
  @Input() action: string;
  @Input() trackProductAnalytics?: string = '';
  @Input() properties?: string = '';
  @Input() isTooltipDisabled?: boolean = false;

  @Output() onMessageUpdated: EventEmitter<string> = new EventEmitter<string>();

  tooltipMessage: TooltipMessage;

  constructor(private _accountStore: MockAccountStoreService) {
    super();
  }

  get shouldDisplayTooltip(): boolean {
    return (
      this.tooltipMessage &&
      this.tooltipMessage.message !== '' &&
      !this.isTooltipDisabled
    );
  }

  get isLinkTooltip(): boolean {
    return this.tooltipMessage?.tooltipType === 'link';
  }

  ngOnInit(): void {
    this._initAccountStore();
  }

  private _initAccountStore() {
    this._accountStore.accountStatus$
      .pipe(this.takeUntilDestroy)
      .subscribe((accountStatus) => {
        // Default behavior is that features wrapped with this component
        // are limited to Basic tiers
        // If the user is on a lower tier, inform them they have to upgrade
        // in order to use that feature
        if (accountStatus?.isFreeTier) {
          this.tooltipMessage = {
            message: `Upgrade your plan to ${this.action}.`,
            tooltipType: 'link',
            url: '/' + accountStatus?.slug + '/account'
          };
        }

        // The user is on the correct tier and the feature does not need
        // to be gated
        else {
          this.tooltipMessage = null;
        }

        // Parents may need to enable/disable buttons based on whether
        // a tooltip/feature gate is being shown, so inform them about
        // the state of the message whenever it changes
        this.onMessageUpdated.emit(this.tooltipMessage?.message);
      });
  }
}
